export default function() {
    let $form = $('.search-form');
    let $searchForm = $('#search-form');
    let $courseSlugInput = $('#course-slug');
    let $selectCourseType = $('select');
    let $tabs = $('.search-form__tab');

    const courseTypes = {
        '1': 'cursos-livres',
        '2': 'idiomas',
        '3': 'graduacao',
        '4': 'pos-graduacao',
        '5': 'cursos-tecnicos',
        '6': 'concursos'
    };

    function isMobile() {
        return $(window).width() <= 1024;
    }

    function updateCourses(courseType) {

        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'POST',
            data: {
                action: 'update_courses',
                course_type: courseType
            },
            beforeSend: function() {
                $('.courses__list').addClass('loading');
            },
            success: function(response) {
                if (response.success) {
                    if (response.data.main_courses) {
                        $('.main-courses .courses__list').html(response.data.main_courses);
                    }
                    if (response.data.online_courses) {
                        $('.courses:not(.main-courses) .courses__list').html(response.data.online_courses);
                    }
                }
            },
            error: function(xhr, status, error) {
                console.error('Error:', error);
            },
            complete: function() {
                $('.courses__list').removeClass('loading');
            }
        });
    }

    function setupEventListeners() {
        if (isMobile()) {
            $selectCourseType.on('change', function() {
                let selectedSlug = courseTypes[$(this).val()] || '';
                $courseSlugInput.val(selectedSlug);
                updateCourses(selectedSlug);
            });
        } else {
            $tabs.on('click', function() {
                $tabs.removeClass('search-form__tab--active');
                $(this).addClass('search-form__tab--active');
                let selectedSlug = courseTypes[$(this).data('tab')] || '';
                $courseSlugInput.val(selectedSlug);
                updateCourses(selectedSlug);
            });
        }
    }

    setupEventListeners();

    $searchForm.on('submit', function(event) {
        event.preventDefault();
        let courseSlug = $courseSlugInput.val();
        let courseTitle = $('input[name="course_title"]').val();
        let cidade = $('input[name="cidade"]').val();
        let cep = $('input[name="cep"]').val();

        let url = window.location.pathname;

        if (!url.includes('pt-br/cursos/')) {
            url = `pt-br/cursos/${courseSlug}/`;
        } else {
            url = `${url.replace(/\/$/, '')}/${courseSlug}/`;
        }

        let params = [];

        if (courseTitle) params.push(`course_title=${encodeURIComponent(courseTitle)}`);
        if (cidade) params.push(`cidade=${encodeURIComponent(cidade)}`);
        if (cep) params.push(`cep=${encodeURIComponent(cep)}`);

        if (params.length > 0) {
            url += '?' + params.join('&');
        }

        window.location.href = url;
    });

    $(window).on('resize', function() {
        setupEventListeners();
    });
}