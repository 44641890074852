export default function setupCookieBanner() {
    document.addEventListener("DOMContentLoaded", () => {
        let banner = document.querySelector(".cookie-banner");
        let acceptButton = document.querySelector(".cookie-btn");
        let closeButton = document.querySelector(".close-btn");

        if (!localStorage.getItem("cookiesAccepted")) {
            banner.style.display = "block";
        }
        acceptButton.addEventListener("click", () => {
            localStorage.setItem("cookiesAccepted", "true");
            hideBanner();
        });
        closeButton.addEventListener("click", () => {
            hideBanner();
        });
        function hideBanner() {
            banner.style.display = "none";
        }
    });
}

setupCookieBanner();
