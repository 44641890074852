"use strict";
import sidebar from "./template-parts/table-of-contents";
import header from "./template-parts/header";
import post_ajax from "./template-parts/post-ajax";
import postsScripts from "./template-parts/posts-scripts";
import searchForm from "./components/search-form";
import singleCourse from "./template-parts/single-course";
import filtering from "./components/filtering";
import cookie from "./components/cookie";
window.$ = $;

let font = document.createElement("link");
font.setAttribute("href", "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Outfit:wght@100..900&display=swap");
font.setAttribute("rel", "stylesheet");
document.body.appendChild(font);
$(window).on("load", (e) => {
    cookie();
    header();
    searchForm();
    sidebar();
    post_ajax();
    postsScripts();
    singleCourse();
    filtering();
});